/* FORM */

.form-group {
    .input-group-text {
        &.is-invalid {
            border-color: #dc3545;
        }
    }
}

.custom-file-label {
    overflow: hidden;
}

.nav-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn {
    &.btn-xs {
        @extend .btn-sm;

        padding: 3px 5px;
    }

    &.btn-outline-black {
        border: 1px solid #000;
        background: transparent;
        color: #000;

        &:hover {
            background: #000;
            color: #fff;
        }
    }
}